import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ImageAndText from "../../../components/sections/ImageAndText";

export interface RedirectOpenBankingDataUsageProps {
  data: {
    site: {
      siteMetadata: {
        downloadActive: boolean;
      };
    };
    security1: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    security2: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    security3: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconShield: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconSlider: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    securityIconShredder: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

const RedirectOpenBankingDataUsage: React.FC<
  RedirectOpenBankingDataUsageProps
> = ({
  data: {
    security1,
    security2,
    security3,
    securityIconShield,
    securityIconSlider,
    securityIconShredder,
  },
}) => {
  /*
  useEffect(() => {
    window.location.replace(`/__/go/faq/`);
  });
  return null;
  */
  const { t } = useTranslation(["sign_up_form", "common", "security"]);

  return (
    <div>
      <div className="px-16 pt-4 pb-16 space-y-16">
        <ImageAndText
          id="server_location"
          img={security1}
          headline={t("security:section_1.title")}
          body={
            <>
              <p>{t("security:section_1.paragraph_1")}</p>
            </>
          }
        />
        <ImageAndText
          id="technology_partners"
          img={security2}
          reversed
          headline={t("security:section_2.title")}
          body={
            <>
              <p>{t("security:section_2.paragraph_1")}</p>
            </>
          }
        />
        <ImageAndText
          id="data_security"
          img={security3}
          headline={t("security:section_3.title")}
          body={
            <>
              <p>{t("security:section_3.paragraph_1")}</p>
              <p>{t("security:section_3.paragraph_2")}</p>
            </>
          }
        />
      </div>
      <div className="py-12 space-y-12 bg-gray-100">
        <h3 className="text-xl text-center font-headline">
          {t("security:principles.title")}
        </h3>
        <div className="grid grid-cols-6 gap-8 container-5xl">
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconShield.childImageSharp.gatsbyImageData}
              alt="shield"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_1.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_1.copytext")}
            </p>
          </div>
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconSlider.childImageSharp.gatsbyImageData}
              alt="settings"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_2.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_2.copytext")}
            </p>
          </div>
          <div className="flex flex-col items-center col-span-6 space-y-4 sm:col-span-2">
            <GatsbyImage
              image={securityIconShredder.childImageSharp.gatsbyImageData}
              alt="shredder"
            />
            <h3 className="text-lg text-center with-hyphens">
              {t("security:principles.principle_3.title")}
            </h3>
            <p className="text-sm text-center text-gray-500">
              {t("security:principles.principle_3.copytext")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectOpenBankingDataUsage;

export const query = graphql`
  query ($language: String!) {
    security1: file(
      relativePath: { eq: "images/security/security-visual-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    security2: file(
      relativePath: { eq: "images/security/security-visual-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    security3: file(
      relativePath: { eq: "images/security/security-visual-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    securityIconShield: file(
      relativePath: { eq: "images/security/visual-shield.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    securityIconSlider: file(
      relativePath: { eq: "images/security/visual-slider.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    securityIconShredder: file(
      relativePath: { eq: "images/security/visual-shredder.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 96
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 48 }
        )
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
